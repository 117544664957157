import React from 'react';
import { RouteComponentProps } from '@reach/router';
import { useStaticQuery, graphql } from 'gatsby';

import { Section, Container, Row, Column, Heading } from '../components/ui';
import {
    DisputeSection,
    ServicesSection,
} from '../components/section_components';
import { Button, WaveDivider, FullwidthCTA, Layout, SEO } from '../components';

import { billBoardText1 } from '../text';

const Page = (props: RouteComponentProps) => {
    const data = useStaticQuery(graphql`
        query Images_v1_1_refactor {
            rocketScores: file(relativePath: { eq: "rocket-scores.svg" }) {
                publicURL
            }
        }
    `);

    return (
        <Layout>
            <SEO
                title="Get Your Free Credit Report and Score | ScoreShuttle"
                description="Start for free to access your free credit report, score, and more today!"
            />
            <Section className="bg-ss-blue-900 pt-24 lg:pt-4">
                <Container className="pb-2">
                    <Row className="flex flex-wrap lg:flex-no-wrap">
                        <Column className="text-white text-center lg:text-left flex-auto lg:w-auto">
                            <div className="container-narrow z-10">
                                <Heading
                                    headingLevel={1}
                                    text="Your mission to a higher credit score starts here."
                                    className="lg:text-left lg:mx-0"
                                />
                                <div className="inline-block text-left">
                                    <p className="font-heading text-xl sm:text-2xl md:text-3xl text-left mb-2">
                                        Start for free<span>*</span> to get:
                                    </p>
                                    <ul className="font-light lg:font-hairline text-left leading-relaxed list-disc pl-5 mb-10 sm:text-lg lg:text-xl">
                                        {[
                                            'FREE 1-Bureau Credit Report and Score',
                                            'FREE ScoreShuttle Credit Report Analysis',
                                            'FREE Score-Boosting Secrets in Credit Camp',
                                        ].map((listItem, index) => (
                                            <li key={index}>{listItem}</li>
                                        ))}
                                    </ul>
                                </div>
                                <Button
                                    theme="blue"
                                    onClick={() =>
                                        window.open(
                                            'https://app.scoreshuttle.com/setup'
                                        )
                                    }
                                >
                                    GET MY FREE CREDIT REPORT AND SCORE
                                </Button>
                            </div>
                        </Column>
                        <Column className="lg:max-w-1/2 lg:w-auto relative z-10 lg:ml-2 xl:pb-2">
                            <img
                                className="mx-auto w-full max-w-sm lg:w-auto lg:max-w-full mt-8 lg:-mt-4 xl:-mt-16"
                                src={data.rocketScores.publicURL}
                                alt="rocket ship"
                            />
                        </Column>
                    </Row>
                </Container>
            </Section>
            <WaveDivider
                foregroundColor="bg-white"
                backgroundColor="text-ss-blue-900"
                flip="y"
            />
            <DisputeSection />
            <ServicesSection />
            <FullwidthCTA />
        </Layout>
    );
};

export default Page;
